<script>
import {
  mapGetters,
  mapActions,
} from 'vuex'

export default {
  name: 'NavMenuMixin',
  computed: {
    ...mapGetters([
      'getItemsMenu',
      'getItemsMenuLoading',
      'getItemsMenuError',
    ]),
    navMenuItems() {
      return this.getItemsMenu
    },
  },
  created() {
    this.inicializar()
  },
  methods: {
    ...mapActions([
      'fetchItemsMenu',
    ]),
    inicializar() {
      this.buscarItemsMenu()
    },
    buscarItemsMenu() {
      this.fetchItemsMenu()
        .catch(error => {
          console.error('Hubo un error al intentar obtener los items de menu', error)
        })
    },
  },
}
</script>
