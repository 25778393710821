<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <dark-Toggler class="d-block" />
    </div>

    <!-- Center -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <p class="text-primary text-center custom-nav-title custom-nav-title-large">
        Asociación de Trabajadores Argentinos de Centros de Contactos
      </p>
      <h3 class="text-primary text-center custom-nav-title custom-nav-title-small">
        A.T.A.C.C
      </h3>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user user-dropdown-menu-right"
      >
        <template #button-content>
          <div class="d-sm-flex user-nav user-name">
            <p
              v-b-tooltip.html="rolUsuario"
              class="font-weight-bolder mb-0"
            >
              {{ nombreUsuario }}
            </p>
            <!--<span class="user-status">{{ rolUsuario }}</span>-->

          </div>

          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="imagenUsuario"
            class="badge-minimal"
            badge-variant="success"
          />

        </template>

        <!-- SE COMENTA PARA USO FUTURO
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />
-->
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="actualizar"
        >
          <font-awesome-icon
            icon="fa-solid fa-rotate-right"
            class="mr-50"
          />
          <span>Actualizar</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Cerrar Sesión</span>
        </b-dropdown-item>

      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {
  mapGetters,
} from 'vuex'

export default {
  components: {
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    nombreUsuario() {
      if (this.getCurrentUser) {
        return `${this.getCurrentUser.nombre} ${this.getCurrentUser.apellido}`
      }
      return 'S/D'
    },
    rolUsuario() {
      let rol = ''
      if (this.getCurrentUser) {
        if (this.getCurrentUser.roles != null && this.getCurrentUser.roles.length > 0) {
          const sortedRoles = Array.from(this.getCurrentUser.roles).sort((a, b) => a.nombre.localeCompare(b.nombre, 'es', { sensitivity: 'base' }))
          sortedRoles.forEach(element => {
            rol += `${element.nombre} <br>`
          })
          // rol = `${this.getCurrentUser.roles[0].nombre}`
        }
      } else {
        rol = 'S/D'
      }
      return `Roles <hr>${rol}`
    },
    imagenUsuario() {
      return ''
    },
  },
  methods: {
    limpiarVuex() {
      this.$store.dispatch('clearStateAuth')
      this.$store.dispatch('setItemsMenu', [])
    },
    limpiarPermisos() {
      window.localStorage.removeItem('ls.X-AUTHENTICATION-TOKEN')
      window.localStorage.removeItem('ls.CURRENT-USER')
      window.localStorage.removeItem('ls.USER-NAME')
      window.localStorage.removeItem('ls.MODULOS-CON-PERMISOS')
      window.localStorage.removeItem('ls.MODULOS-CON-PERMISOS-INS')
      window.localStorage.removeItem('ls.MODULOS-CON-PERMISOS-UPD')
      window.localStorage.removeItem('ls.MODULOS-CON-PERMISOS-DEL')
      window.localStorage.removeItem('ls.MODULOS-CON-PERMISOS-VER')
    },
    limpiarLastVariables() {
      window.localStorage.removeItem('abm_lastSearch')
      window.localStorage.removeItem('config_beneficio_page_size_lastset')
      window.localStorage.removeItem('usuario_page_size_lastset')
      window.localStorage.removeItem('config_beneficio_lastSearch')
      window.localStorage.removeItem('stock_page_size_lastset')
      window.localStorage.removeItem('abm_stock_lastSearch')
      window.localStorage.removeItem('lastPath')
    },
    logout() {
      this.limpiarVuex()
      this.limpiarPermisos()
      this.limpiarLastVariables()
      this.$router.push({ name: 'login' })
    },
    actualizar() {
      window.location.reload()
    },
  },
}
</script>
<style lang="scss" scoped>
.user-dropdown-menu-right {
  right: -1rem;
}
.custom-nav-title {
  margin: 0;
  font-size: 3vmin;
  &-large {
    display: block;
  }
  &-small {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .user-dropdown-menu-right {
    padding: 0 0.5rem 0 0;
  }
  .user-name {
    font-size: 0.6rem;
  }
  .custom-nav-title {
    &-large {
      display: none;
    }
    &-small {
      font-size: 3vmax;
      display: block;
    }
  }
}
</style>
